<template>
  <div class="page">
    <section class="section-1">
      <img src="../../assets/images/about/banner.png" alt="" />
      <div class="content">浙江刘小白智能科技有限公司</div>
    </section>
    <section class="section-2">
      <div class="content">
        <ul class="idea">
          <li class="item" v-for="(item, index) in ideaList" :key="index">
            <div class="img">
              <img
                :src="
                  require('../../assets/images/about/' + item.imgUrl + '.svg')
                "
                alt=""
              />
            </div>
            <div class="theme">{{ item.theme }}</div>
            <div class="depict">{{ item.depict }}</div>
          </li>
        </ul>
      </div>
    </section>
    <section class="section-3">
      <div class="content">
        <div class="text-col-1">
          <div class="title">公司简介</div>
          <div class="text">
            <p>
              刘小白是一家设计生产智能出纸机设备的智能科技企业。智能出纸机是刘小白旗下独立研发的“智慧厕所生态”核心产品，是拥有自主知识产权的物联网终端设备。通过产品物联网属性和居
            </p>
            <p>
              间作用，构建公共卫生设备的去中心化和厕所场景的智能化。在提升用户体验、提高运维效率的基础上，把厕所革命与互联网共享商业模式创新性的关联起来。
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section-4">
      <div class="content">
        <div class="title">技术实力</div>
        <div class="tech-list">
          <div class="item">
            <img src="../../assets/images/about/tech-1.png" alt="" />
            <div class="company">华为硬件通信</div>
            <div class="line"></div>
            <div class="introduction">
              <p>华为解决方案合作伙伴</p>
              <p>NB-loT、LoRa物联网技术合作伙伴</p>
              <p>华为云服务技术支持</p>
            </div>
          </div>
          <div class="item">
            <img src="../../assets/images/about/tech-2.png" alt="" />
            <div class="company">腾讯软件平台</div>
            <div class="line"></div>
            <div class="introduction">
              <p>微信平台技术支持</p>
              <p>微信小程序合作伙伴</p>
            </div>
          </div>
          <div class="item">
            <img src="../../assets/images/about/tech-3.png" alt="" />
            <div class="company">小米产品标准</div>
            <div class="line"></div>
            <div class="introduction">
              <p>小米供应链制造商提供</p>
              <p>执行生态链产品生产标准</p>
              <p>商业模式及产品理论支持</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-5">
      <div class="content">
        <div class="team-title">合作伙伴</div>
        <div class="team-img">
          <img src="../../assets/images/about/team-list.png" alt="" />
        </div>
        <div class="patent-text">
          <p class="patent-title">专利证书</p>
          <p class="patent-content patent-row-2">
            强劲的自主研发实力&nbsp;&nbsp;&nbsp;打造知识产权壁垒
          </p>
          <p class="patent-content patent-row-3">
            啦撒智能出纸机设备、啦撒后台、前端等皆为自主研发，拥有实用新型与外观设计等核心知识产权近三十余项。
          </p>
        </div>
        <div class="patent-img">
          <img src="../../assets/images/about/patent-list.png" alt="" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AboutIndex",
  data() {
    return {
      ideaList: [
        {
          imgUrl: "idea-1",
          theme: "愿景",
          depict: "智慧卫生间的引领者",
        },
        {
          imgUrl: "idea-2",
          theme: "使命",
          depict: "用科技改善大众生活",
        },
        {
          imgUrl: "idea-3",
          theme: "核心价值观",
          depict: "用户体验 团队合作 开放进取",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.page {
  .section-1 {
    margin-top: -63px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      z-index: -1;
      width: 100vw;
      height: 27.03vw;
    }

    .content {
      position: absolute;
      z-index: 99;
      height: 67px;
      font-size: 48px;
      font-weight: bold;
      line-height: 67px;
    }
  }

  .section-2 {
    width: 100vw;
    height: 288px;
    background: #ffffff;

    .content {
      margin: 0 auto;
      width: 1200px;
      height: 100%;
      display: flex;
      .idea {
        margin-top: 80px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .item {
          width: 200px;
          height: 144px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          position: relative;
          .img {
            width: 68px;
            height: 68px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .theme {
            font-size: 20px;
            font-weight: bold;
            line-height: 28px;
          }
          .depict {
            font-size: 14px;
            line-height: 20px;
          }
        }
        .item:nth-child(n + 2)::before {
          content: "";
          width: 1px;
          height: 98px;
          background: #cccccc;
          position: absolute;
          top: 24px;
          left: -125px;
        }
      }
    }
  }

  .section-3 {
    width: 100vw;
    height: 92px;
    background: #ffffff;

    .content {
      margin: 0 auto;
      width: 1200px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .text-col-1 {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          height: 28px;
          font-size: 20px;
          font-weight: bold;
          line-height: 28px;
        }
        .text {
          margin-top: 20px;
          height: 44px;
          font-size: 14px;
          color: #555555;
          line-height: 22px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  .section-4 {
    margin-top: 80px;
    width: 100vw;
    height: 565px;
    background: #ffffff;

    .content {
      margin: 0 auto;
      width: 1200px;
      height: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        height: 28px;
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
      }
      .tech-list {
        margin-top: 40px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .item {
          width: 355px;
          height: 497px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #ffffff;
          box-shadow: 0 5px 12px 0 rgba(185, 185, 185, 0.5);
          border-radius: 2px;
          .company {
            margin-top: 40px;
            height: 28px;
            font-size: 20px;
            font-weight: bold;
            line-height: 28px;
          }
          .line {
            margin-top: 20px;
            width: 19px;
            height: 4px;
            background: #fe690c;
          }
          .introduction {
            margin-top: 20px;
            height: 92px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            color: #555555;
            line-height: 20px;
          }
        }
      }
    }
  }

  .section-5 {
    margin-top: 80px;
    width: 100vw;
    height: 1432px;
    background: #ffffff;
    .content {
      margin: 0 auto;
      width: 1200px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .team-title {
        height: 28px;
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
      }
      .team-img {
        margin-top: 40px;
        width: 1200px;
        height: 450px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .patent-text {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .patent-title {
          margin-top: 20px;
          height: 28px;
          font-size: 20px;
          font-weight: bold;
          line-height: 28px;
        }
        .patent-content {
          margin-top: 16px;
          height: 20px;
          font-size: 14px;
          color: #555555;
          line-height: 20px;
        }
        .patent-row-2 {
          font-weight: bold;
        }
      }
      .patent-img {
        margin-top: 40px;
        width: 1200px;
        height: 610px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
